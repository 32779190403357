import styled from "styled-components";
import AntBreadCrumbs from "../../uiKits/BreadCrums";

export const StyledBreadCrumbs = styled(AntBreadCrumbs)``;

export const MainHeading = styled.h2`
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 85%;
  margin: 0 auto;
`;
