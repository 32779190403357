// UserForm.js
import React from "react";
import { Form, Input, Select, Button, message } from "antd";
import { UserFormWrapper, StyledButton } from "./styles";
import { useCreateUserMutation } from "../../../api/authApi";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const UserForm = () => {
  const [form] = Form.useForm();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await createUser(values).unwrap();
      message.success("User created successfully");
      navigate('/dashboard');
    } catch (err) {
      message.error(err.data?.message || "Failed to create user");
    }
  };
  return (
    <UserFormWrapper>
      <h3>Users / Add User</h3>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item label="First Name" name="firstName">
          <Input placeholder="647" />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input placeholder="5560" />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Phone Number" name="phoneNumber">
          <Input placeholder="Ronald Richards" />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Input placeholder="4" />
        </Form.Item>
        <Form.Item label="User Role" name="userRole">
          <Select placeholder="Select Status">
            <Option value="admin">Admin</Option>
            <Option value="user">User</Option>
          </Select>
        </Form.Item>
        <StyledButton type="primary" htmlType="submit">
          Add User
        </StyledButton>
      </Form>
    </UserFormWrapper>
  );
};

export default UserForm;
