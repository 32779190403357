import styled from "styled-components";

export const TableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-weight: bold;
    text-align: left;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .ant-btn {
    margin: 0 4px;
  }

  .ant-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
`;
